import i18next from 'i18next';
import { courses } from '../constants/courses.js';
import {
	backendCourseCode,
	dtoCourseCode,
	dtoDateCode,
	getAsync,
	postAsync,
	putAsync,
} from 'common-frontend/utils/fetch-helper';
import { alertUser } from 'common-frontend/utils/alert';
import { STORES } from './stores';

// BUTTON RESET FLOW
export const RESET_SCORE = async (course) => {
	const {
		WalletStore: { setScore, setDate },
	} = STORES;
	const coursesToReset = courses.slice(course === 'all' ? 0 : courses.indexOf(course), courses.length); // resetting scores for the selected course and courses that come after the selected one

	for (const c of coursesToReset) {
		await PUT_SCORE(c, 0, () => {
			setScore(c, 0);
			setDate(c, null);
		});
	}
};

// BUTTON SYNC WALLET
export const UPDATE_SCORE = async (course) => {
	const {
		WalletStore: { setScore },
	} = STORES;
	const curScoreRes = await getAsync('/score');

	if (curScoreRes.status === 200) {
		const data = await curScoreRes.json();
		const currentScore = data[dtoCourseCode(course)];
		console.info('current score ', currentScore);
		const newScore = currentScore + 25 + Math.floor(Math.random() * 25);
		console.info('new score', newScore > 100 ? 100 : newScore);

		await PUT_SCORE(course,
			newScore > 100 ? 100 : newScore,
			async (response) => {
				const data = await response.json();
				setScore(course, data[dtoCourseCode(course)]);
			}
		);
	}
};

// BUTTON SHOW SCORE
export const GET_SCORE = async (course) => {
	const {
		WalletStore: { setScore, setDate },
	} = STORES;
	const response = await getAsync('/score');

	if (response.status === 200) {
		const data = await response.json();
		const coursesToUpdate = course === 'all' ? courses : [ course ];

		coursesToUpdate.forEach(c => {
			const score = data[dtoCourseCode(c)];
			const date = data[dtoDateCode(c)];

			setScore(c, score);
			setDate(c, date);
		});
	}
};

// BUTTON SEND PDF
export const SEND_PDF = async (course) => {
	await postAsync('/send-pdf', { course: backendCourseCode(course) });
};

export const PUT_SCORE = async (course, score, onSuccess = null) => {
	const response = await putAsync('/score', { course: backendCourseCode(course), score });

	if (response.status === 200 && onSuccess) {
		onSuccess(response);
	}
};


export const EXAM_REGISTRATION = async () => {
	const response = await postAsync('/request-attributes-and-share-certificate', {});

	if (response.status === 200) {
		alertUser(i18next.t('alerts.exam-registered'), 'success');
	}
};

export const PASS_EXAM = async () => {
	await postAsync('/pass-exam', {});
};

export const GET_EXAM = async () => {
	const {
		WalletStore: { setExam },
	} = STORES;
	const response = await getAsync('/exam/state');

	if (response.status === 200) {
		const data = await response.json();
		setExam(data);
		sessionStorage.setItem('exam_status', data.state);
	}
};

export const SEND_JOURNEYMAN_CERT = async () => {
	const response = await postAsync('/send-certificate');

	if (response.status === 200) {
		alertUser(i18next.t('alerts.jouyrneyman-sert-sent'), 'success');
	}
};

export const REGISTER_TO_COURSE = async (course, date) => {
	await postAsync('/register-to-course', { course: backendCourseCode(course), date });
};